<template>
    <div class="err-container">
        <div class="err-main">
            <div class="err_404"></div>
            <div class="err-title">抱歉，找不到頁面</div>
            <div>
                <a href="/">
                    <button class="err err-btn">回到首頁</button>
                </a>
                <div class="logo-out">
                    或 <span @click="$logout()">登入其他帳號</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    mounted() {
        this.$reLoginGetPermissions()
    }
}
</script>

<style lang="scss" scoped>
.err-container {
    overflow: scroll;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $secondary-white;
    margin: 0 auto;
    display: flex;
    height: 100vh;
    width: 100%;

    h1 {
        font-weight: 100;
        text-align: center;
        font-size: 2.3em;
    }
}

.err-main {
    width: 50%;
    text-align: center;
    display: inline-block;

    @media (max-width: 767px) {
        width: 90%;
    }
}

.err_404 {
    background-image: url('../assets/images/img_404_bg.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    margin-top: 100px;
    margin-bottom: 40px;

    @media (max-width: 767px) {
        height: 270px;
        margin-top: 60px;
        margin-bottom: 0px;
    }

    @media (max-width: 767px) and(orientation: landscape) {
        height: 150px;
    }
}

.err-title {
    text-align: center;
    color: $fourth-black;
    font-size: 28px;
    margin-bottom: 40px;
    font-family: $primary-cn;
    font-weight: 700;

    @media (max-width: 767px) and(orientation: landscape) {
        font-size: 20px;
    }
}

.err {
    font-size: 14px;
    font-family: $primary-cn;
    font-weight: 700;
    height: 40px;
    width: 100px;
    padding: 0 8px;
    border-radius: 3px;
}

.err-btn {
    border: 0px;
    color: $primary-white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    background-color: $primary-red;

    @media (max-width: 767px) and(orientation: landscape) {
        height: 30px;
    }
}

.logo-out {
    margin-top: 20px;
    span {
        text-decoration: underline;
        color: $fourth-blue;
        cursor: pointer;
    }
}
</style>
