// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/img_404_bg.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".err-container[data-v-46a88b29] {\n  overflow: scroll;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  background-color: #f7f7f7;\n  margin: 0 auto;\n  display: flex;\n  height: 100vh;\n  width: 100%;\n}\n.err-container h1[data-v-46a88b29] {\n    font-weight: 100;\n    text-align: center;\n    font-size: 2.3em;\n}\n.err-main[data-v-46a88b29] {\n  width: 50%;\n  text-align: center;\n  display: inline-block;\n}\n@media (max-width: 767px) {\n.err-main[data-v-46a88b29] {\n      width: 90%;\n}\n}\n.err_404[data-v-46a88b29] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n  height: 300px;\n  margin-top: 100px;\n  margin-bottom: 40px;\n}\n@media (max-width: 767px) {\n.err_404[data-v-46a88b29] {\n      height: 270px;\n      margin-top: 60px;\n      margin-bottom: 0px;\n}\n}\n@media (max-width: 767px) and (orientation: landscape) {\n.err_404[data-v-46a88b29] {\n      height: 150px;\n}\n}\n.err-title[data-v-46a88b29] {\n  text-align: center;\n  color: #354052;\n  font-size: 28px;\n  margin-bottom: 40px;\n  font-family: \"Noto Sans TC\";\n  font-weight: 700;\n}\n@media (max-width: 767px) and (orientation: landscape) {\n.err-title[data-v-46a88b29] {\n      font-size: 20px;\n}\n}\n.err[data-v-46a88b29] {\n  font-size: 14px;\n  font-family: \"Noto Sans TC\";\n  font-weight: 700;\n  height: 40px;\n  width: 100px;\n  padding: 0 8px;\n  border-radius: 3px;\n}\n.err-btn[data-v-46a88b29] {\n  border: 0px;\n  color: #ffffff;\n  text-shadow: 0 1px rgba(0, 0, 0, 0.1);\n  background-color: #e52d27;\n}\n@media (max-width: 767px) and (orientation: landscape) {\n.err-btn[data-v-46a88b29] {\n      height: 30px;\n}\n}\n.logo-out[data-v-46a88b29] {\n  margin-top: 20px;\n}\n.logo-out span[data-v-46a88b29] {\n    text-decoration: underline;\n    color: #00adee;\n    cursor: pointer;\n}\n", ""]);
// Exports
module.exports = exports;
